<script src="../../../store/index.js"></script>
<template>
    <div style="width: 1500px;height: auto;overflow: hidden;background: #FFFFFF;border-radius: 5px 5px 5px 5px;">
        <div style="margin-top: 20px;margin-left: 40px;float: left">
            <el-tag v-if="Info.type == 1"  style="margin-left: 15px;"><span style="margin: 0 5px">招标</span></el-tag>
            <el-tag v-if="Info.type == 2"  style="margin-left: 15px;"><span style="margin: 0 5px">采购</span></el-tag>
            <el-tag v-if="Info.type == 3"  style="margin-left: 15px;"><span style="margin: 0 5px">招募</span></el-tag>
            <el-tag v-if="Info.type == 4"  style="margin-left: 15px;"><span style="margin: 0 5px">商务</span></el-tag>
            <el-tag v-if="Info.type == 5"  style="margin-left: 15px;"><span style="margin: 0 5px">招商</span></el-tag>
            <el-tag v-if="Info.type == 6"  style="margin-left: 15px;"><span style="margin: 0 5px">需求</span></el-tag>
        </div>
        <div style="height: 40px;font-size: 28px;color: #444444;margin-top: 14px;">
            <span style="margin-left: 20px;float: left">{{Info.title}}</span>
        </div>
        <div style="display: flex;justify-content:space-between;margin-left: 55px;">
            <div style="display: flex">
                <div style="font-size: 20px;margin-top: 40px;font-weight: bold;color: #3C56CF">发布者：</div>
                <div style="width: 50px;height: 50px;margin-top: 36px;">
                    <img :src="Info.headIco" style="border-radius: 50%;width: 70%;height: 70%;">
                </div>
                <div style="color: #444444;font-size: 20px;margin-top: 40px;font-weight: bold">{{Info.nickName}}</div>
            </div>
            <div v-if="Info.other && Info.other.budget > 0 && Info.type != 3" style="height: 45px;font-size: 36px;color: #FF0019;margin-right: 60px;margin-top: 30px;">￥{{Info.other.budget}}元<span style="height: 26px;font-size: 20px;margin-left: 10px;color: #1a1a1a">预算</span></div>
        </div>
        <div style="width: 1380px;border: 1px solid #CCCCCC;float: left;margin: 10px 0 0 50px;"></div>
        <!-- 内容 -->
        <el-descriptions style="margin-left: 60px;color: #444444;margin-top: 30px;float: left;margin-right: 60px;">
            <el-descriptions-item v-if="Info.type === 1" label="项目概况" style="color: #1a1a1a;" >
                <div style="white-space: pre-wrap;" v-html="Info.content"></div>
            </el-descriptions-item>
            <el-descriptions-item v-if="Info.type === 2" label="采购详情描述" style="color: #1a1a1a;">
                <div style="white-space: pre-wrap;" v-html="Info.content"></div>
            </el-descriptions-item>
            <el-descriptions-item v-if="Info.type === 3" label="岗位职责" style="color: #1a1a1a">
                <div style="white-space: pre-wrap;" v-html="Info.content"></div>
            </el-descriptions-item>
            <el-descriptions-item v-if="Info.type === 4" label="商务概况" style="color: #1a1a1a">
                <div style="white-space: pre-wrap;" v-html="Info.content"></div>
            </el-descriptions-item>
            <el-descriptions-item v-if="Info.type === 5" label="项目概况及规模" style="color: #1a1a1a">
                <div style="white-space: pre-wrap;" v-html="Info.content"></div>
            </el-descriptions-item>
            <el-descriptions-item v-if="Info.type === 6" label="具体内容及要求" style="color: #1a1a1a">
                <div style="white-space: pre-wrap;" v-html="Info.content"></div>
            </el-descriptions-item>
        </el-descriptions>
        <div style="clear: both">
            <!--   具体要求      -->
            <el-descriptions style="margin-left: 60px;margin-top: 20px;margin-right: 60px;">
                <el-descriptions-item v-if="Info.type === 1" label="具体要求" style="color: #1a1a1a;">
                    <div style="white-space: pre-wrap;" v-html="Info.other.content"></div>
                </el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 2" label="采购规格描述" style="color: #1a1a1a">
                    <div style="white-space: pre-wrap;" v-html="Info.other.content"></div>
                </el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 3" label="条件要求" style="color: #1a1a1a">
                    <div style="white-space: pre-wrap;" v-html="Info.other.content"></div>
                </el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 4" label="合作事项和内容" style="color: #1a1a1a">
                    <div style="white-space: pre-wrap;" v-html="Info.other.content"></div>
                </el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 5" label="优惠政策" style="color: #1a1a1a">
                    <div style="white-space: pre-wrap;" v-html="Info.other.content"></div>
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <div style="float: left">
            <!-- 通用类型  -->
            <el-descriptions  style="margin-left: 60px;">
                <el-descriptions-item v-if="Info.type === 1" label="招标类型">招标</el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 2" label="采购类型">采购</el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 3" label="招募类型">招募</el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 4" label="商务类型">商务</el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 5" label="招商类型">招商</el-descriptions-item>
                <el-descriptions-item v-if="Info.type === 6" label="需求类型">需求</el-descriptions-item>
                <el-descriptions-item label="项目所在地">{{Info.projectAddress}}</el-descriptions-item>
                <el-descriptions-item label="报名截止日期">{{Info.endTime}}</el-descriptions-item>
                <el-descriptions-item label="发布日期">{{Info.createTime | parseTime('{y}-{m}-{d}')}}</el-descriptions-item>
                <el-descriptions-item label="联系人">{{Info.contacts}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{Info.phone}}</el-descriptions-item>
                <!--  项目分包  -->
                <template v-if="Info.type == 1">
                    <el-descriptions-item v-if="Info.other.serviceMode == 1" label="本项目要求的服务方式">云端服务</el-descriptions-item>
                    <el-descriptions-item v-if="Info.other.serviceMode == 2" label="本项目要求的服务方式">驻场服务</el-descriptions-item>
                    <template v-if="Info.other.isQualification == 1">
                        <el-descriptions-item  label="资质名称">{{Info.other.qualificationName}}</el-descriptions-item>
                        <el-descriptions-item  label="资质等级">{{Info.other.qualificationLv}}</el-descriptions-item>
                    </template>
                </template>

                <template v-if="Info.type == 2">
                    <el-descriptions-item   label="本信息要求的采购方式" v-if="Info.other.purchaseType == 1">政府企业</el-descriptions-item>
                    <el-descriptions-item   label="本信息要求的采购方式" v-if="Info.other.purchaseType == 2">企业采购</el-descriptions-item>
                    <el-descriptions-item   label="本信息要求的采购方式" v-if="Info.other.purchaseType == 3">个人采购</el-descriptions-item>
                </template>

                <template v-if="Info.type == 3">
                    <el-descriptions-item   label="招募类型" v-if="Info.other.recruitType == 1">全职</el-descriptions-item>
                    <el-descriptions-item   label="招募类型" v-if="Info.other.recruitType == 2">兼职</el-descriptions-item>
                    <el-descriptions-item   label="本招募信息的结算方式" v-if="Info.other.recruitPayType == 1">日结</el-descriptions-item>
                    <el-descriptions-item   label="本招募信息的结算方式" v-if="Info.other.recruitPayType == 2">周结</el-descriptions-item>
                    <el-descriptions-item   label="本招募信息的结算方式" v-if="Info.other.recruitPayType == 3">月结</el-descriptions-item>
                    <el-descriptions-item   label="本招募信息的结算方式" v-if="Info.other.recruitPayType == 4">完工结算</el-descriptions-item>
                    <el-descriptions-item v-if="Info.other.workTime > 0"  label="工作时间">{{Info.other.workTime}}小时</el-descriptions-item>
                    <el-descriptions-item v-if="Info.other.recruitNum > 0"  label="招募人数">{{Info.other.recruitNum }}人</el-descriptions-item>
                    <el-descriptions-item v-if="Info.other.Salary > 0"  label="薪酬（元）">{{Info.other.Salary}}元</el-descriptions-item>
                </template>
            </el-descriptions>
            <div style="float: left">
                <div v-if="Info.images && Info.images.length>0" style="margin-left: 70px;">
                    <div v-for="(item,index) in Info.images" :key="index" style="margin-top:10px;float: left;margin-right: 10px">
                        <img :src="item.img_url" style="width: 670px;height:485px;">
                    </div>
                </div>
                <div style="margin-left: 60px;margin-top: 10px;">
                    <div @click="videosList = true" v-if="Info.videos != null && Info.videos.length>0" style="width: 300px;">
                        <div class="image-container" v-for="(vid,index) in Info.videos" :key="index">
                            <img :src="vid.img_url" style="width: 300px;height: 168px">
                            <img class="centered-image" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon13.png">
                        </div>
                    </div>
                </div>
                <el-dialog :close-on-click-modal="false" :visible.sync="videosList" width="50%">
                     <video-player class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                </el-dialog>
                <div v-if="Info.files && Info.files.length >0">
                    <div style="margin-left: 60px;width: 100%;height: 48px;background: #F1F1F1;margin-top: 5px;" v-for="(item,index) in Info.files" :key="index">
                        <div style="width: 22px;height: 28px;padding: 10px 22px;float: left">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon10.png" style="width: 22px;height: 28px;">
                        </div>
                        <div style="height: 19px;font-size: 16px;float: left;padding: 13px 0;width: 1000px;">{{item.name}}</div>
                        <div style="float: right;margin: 15px 20px;cursor: pointer" @click="download(item['url'],item['name'],item['hash'])">
                            <div style="float: left;margin-top: 3px"><svg t="1694497757251" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4270" width="20" height="18"><path d="M828.975746 894.125047 190.189132 894.125047c-70.550823 0-127.753639-57.18542-127.753639-127.752616L62.435493 606.674243c0-17.634636 14.308891-31.933293 31.93227-31.933293l63.889099 0c17.634636 0 31.93227 14.298658 31.93227 31.933293l0 95.821369c0 35.282574 28.596292 63.877843 63.87682 63.877843L765.098927 766.373455c35.281551 0 63.87682-28.595268 63.87682-63.877843l0-95.821369c0-17.634636 14.298658-31.933293 31.943526-31.933293l63.877843 0c17.634636 0 31.933293 14.298658 31.933293 31.933293l0 159.699212C956.729385 836.939627 899.538849 894.125047 828.975746 894.125047L828.975746 894.125047zM249.938957 267.509636c12.921287-12.919241 33.884738-12.919241 46.807049 0l148.97087 148.971893L445.716876 94.89323c0-17.634636 14.300704-31.94762 31.933293-31.94762l63.875796 0c17.637706 0 31.945573 14.312984 31.945573 31.94762l0 321.588299 148.97087-148.971893c12.921287-12.919241 33.875528-12.919241 46.796816 0l46.814212 46.818305c12.921287 12.922311 12.921287 33.874505 0 46.807049L552.261471 624.930025c-1.140986 1.137916-21.664416 13.68365-42.315758 13.69286-20.87647 0.010233-41.878806-12.541641-43.020816-13.69286L203.121676 361.13499c-12.922311-12.933567-12.922311-33.884738 0-46.807049L249.938957 267.509636 249.938957 267.509636z" fill="#FF9800" p-id="4271"></path></svg></div>
                            <div style="float: left;width: 32px;height: 18px;color: #FF9800;font-size: 16px;" >下载</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="is_login === 1">
           <div v-if="Info.isSignUp === 0 ||  (Info.signUpList && Info.signUpList.length === 0)" style="width: 1380px;border: 1px solid #CCCCCC;float: left;margin: 20px 0 0 50px;"></div>
           <div v-if="Info.isSignUp === 0">
               <div v-show="Info.uuid !== user.uuid && Info.status === 1" style="width: 212px;border-radius: 5px 5px 5px 5px;float: right;display: flex;justify-content: center;align-content: center;margin-right: 20px;margin-top: 20px;margin-bottom: 10px;">
                   <!--  机构账号不能报名招募信息  其它的可以看个人档案  招募信息只针对个人 --> <!--   招商信息只能机构报名 -->
                    <!--   type 1 招标信息  type 2 采购信息   type 3 招募信息  type 4 商务信息   type 5 招商信息   type 6 需求信息-->
                    <!--  user.userType 1 个人账号   user.userType 2 企业账号  -->
                   <el-button type="primary" @click="addBusinessSignup">报名此需求</el-button>
                    <!--   <label v-if="Info.type !== 3 && Info.type !== 5">-->
                    <!--        <el-button v-if="user.userType === 1" type="primary" @click="addBusinessSignup">报名此需求</el-button>-->
                    <!--        <el-button v-else-if="user.userType === 2" type="primary" @click="addBusinessSignup">报名此需求</el-button>-->
                    <!--   </label>-->
                    <!--        <el-button v-else-if="Info.type === 3 && user.userType != 2" type="primary" @click="addBusinessSignup">报名此需求</el-button>-->
                    <!--        &lt;!&ndash;   招商信息只能机构报名 &ndash;&gt;-->
                    <!--        <el-button v-if="Info.type === 5 && user.userType == 2" type="primary" @click="addBusinessSignup">报名此需求</el-button>-->
               </div>
           </div>
        </div>
        <div v-if="is_login === 0">
            <div style="width: 1380px;border: 1px solid #CCCCCC;float: left;margin: 20px 0 0 50px;"></div>
            <div style="width: 212px;border-radius: 5px 5px 5px 5px;float: right;display: flex;justify-content: center;align-content: center;margin-right: 20px;margin-top: 20px;margin-bottom: 10px;">
                <el-button type="primary" @click="addBusinessSignup">报名此需求</el-button>
            </div>
        </div>
        <div v-if="is_login === 1">
            <div v-if="Info.status === 1">
                <div v-if="Info.uuid === user.uuid && (!Info.signUpList || Info.signUpList.length === 0)">
                      <div style="float: right;margin: 15px 50px 10px 0"><el-button type="danger" class="el-icon-delete" round @click="undomessage">撤销信息</el-button></div>
                </div>
            </div>
            <div v-if="Info.status === 0 && Info.uuid === user.uuid">
                <div style="float: right;margin: 15px 70px 10px 0;font-size: 20px;font-weight: 600;color: red;cursor: pointer">已撤销</div>
            </div>
        </div>

       <div style="height: 10px;background: #FFFFFF;float: left"></div>

    </div>
</template>

<script>
import {formatTime, parseTime} from '@/utils/utils'
export default {
    components: {},
    filters: {
        parseTime, formatTime
    },
    props: {
        Info: {
            type: Object,
            default() {
                return {}
            }

        }
    },

    data() {
        return {
            playerOptions: {},
            videos:[],
            images:[],
            myshop:{},
            videosList:false,
        }
    },
    watch:{
        // data(){
        //     this.info = this.data;
        //     //  初始化加载视频
        //     this.init();
        //
        // }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
        is_login(){
            return this.$store.state.is_login
        },
    },

    mounted() {
        this.id = this.$route.params.id;
    },

    methods: {
        // 下载附件
        download(url,name,hash){
            this.utils.download(url,name,hash)
        },
        addBusinessSignup(){
            var that = this;

            if (this.is_login === 0) {
                this.$store.commit('setShowLoginPopup', true);
                return
            }
            if (that.user.userType == 3){
                this.utils.err('子账号不能报名');
                return false;
            }
            // 招募信息只能个人报名
            if (that.Info.type == 3){
                if (that.user.userType != 1){
                    this.utils.err('招募信息仅个人账号报名');
                    return false;
                }
            }
            // 招商信息只能机构报名
            if (that.Info.type == 5){
                if (that.user.userType != 2){
                    this.utils.err('招商信息仅机构账号报名');
                    return false;
                }
            }

          if (!this.utils.toAuth(1)) {
            return false
          }
            if(that.user.userType === 1){
              if (!this.utils.toAuth(2)) {
                return false
              }
            }
            if(that.user.userType === 1){
                if(that.Info.type === 2){
                    that.newApi.getMyShop({}).then((ret)=>{
                        that.myshop = ret.data;
                        if(that.myshop === null){
                            that.utils.err('请先开通店铺');
                            return false;
                        } else if(that.myshop.status === 2 || that.myshop.status === 4) {
                            that.utils.err("您的店铺审核中或者已查封");
                            return false;
                        }
                        var params = {};
                        params.uuid = that.user.uuid;
                        params.bid = that.id;
                        that.newApi.addBusinessSignup(params).then((res) => {
                            if(res.isSuccess == 1){
                                that.utils.sus(res.data);
                                that.$parent.successful();
                            }
                        });
                    }).catch((err)=>{
                        console.log(err)
                    })
                }else{
                    var params = {};
                    params.uuid = that.user.uuid;
                    params.bid = that.id;
                    that.newApi.addBusinessSignup(params).then((res) => {
                        if(res.isSuccess == 1){
                            that.utils.sus(res.data);
                            that.$parent.successful();
                        }
                    })
                }
            }else if(that.user.userType === 2){
                var params = {};
                params.uuid = that.user.uuid;
                params.bid = that.id;
                that.newApi.addBusinessSignup(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.$parent.successful();
                    }
                })
            }
        },
        init(){
            var that = this;
            for (let i = 0; i< that.Info.videos.length;i++){
                that.playerOptions = {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: that.Info.videos[i].url// url地址
                    }],
                    poster: that.Info.videos[i].img_url, // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                }
            }
        },
        // 撤销商务信息
        undomessage(){
            var that= this;
            that.utils.confirm("确定要删除？",function(){
                that.newApi.cancelMyBusinessInfo({
                    id:that.id
                }).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.$emit('cancel-edit');
                        that.utils.confirm("已撤销，是否要关闭窗口?",function(){
                            window.close(); // 关闭当前窗口
                        })
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            })
        }

    },
}

</script>
<style scoped>
/deep/ .el-descriptions {
    font-size:20px;
}
/deep/ .el-tag {
    font-size: 17px;
}
.image-container {
    position: relative;
    width: 300px;
    height: 168px;
}

.centered-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
}
</style>
