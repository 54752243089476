<template>
    <div>
         <div v-for="(item,index) in Info.signUpList" :key="index" style="width: 1500px;overflow: hidden;background: #FFFFFF;margin-top: 5px;min-height: 130px;">
            <div class="Head" @click="getArchives(item.uuid)">
                <img :src="item.headIco" style="width: 90px;height: 90px;border-radius:5px;object-fit: cover">
            </div>
           <div style="float: left">
               <div style="display: flex">
                   <div class="name">{{item.nickName}}</div>
                   <div style="float: left;margin: 30px 0 0 10px;">
                      <el-tag v-if="item.userType === 1">个人</el-tag>
                      <el-tag v-if="item.userType === 2">企业</el-tag>
                   </div>
               </div>
               <div style="margin-left: 13px;text-align: center">
                   <div style="float: left">
                       <svg t="1681700453847" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3290" width="12" height="12">
                           <path d="M511.913993 63.989249C317.882076 63.989249 159.973123 221.898203 159.973123 415.930119c0 187.323366 315.473879 519.998656 328.890979 534.103813 6.020494 6.364522 14.449185 9.976818 23.221905 9.976818 0.172014 0 0.516042 0 0.688056 0 8.944734 0 17.545439-4.128339 23.393919-11.008903l109.22896-125.054258c145.179909-177.690576 218.629934-314.957836 218.629934-407.845456C864.026877 221.898203 706.117924 63.989249 511.913993 63.989249zM511.913993 575.903242c-88.415253 0-159.973123-71.55787-159.973123-159.973123s71.55787-159.973123 159.973123-159.973123 159.973123 71.55787 159.973123 159.973123S600.329246 575.903242 511.913993 575.903242z" fill="#FF9800" p-id="3291"></path>
                       </svg>
                   </div>
                   <div  style="float: left;margin-left: 1px;text-align: center">
                       <div class="now" style="color: #999999;font-size: 12px;margin-top:3px">乌鲁木齐</div>
                   </div>
               </div>
               <div style="font-size: 13px;clear: both;margin-left: 15px;color: #999999;padding-top: 5px;">{{item.updateTime | parseTime('{y}-{m}-{d} {h}:{m}:{s}')}}</div>
               <div style="clear: both;width: 1150px;margin: 15px;padding-top: 5px;" >
                        <!--  <div style="font-size: 20px;color: #666666;" ><span v-for="(label, ind) in JSON.parse(item.explainInfo).businessSkills.value" :key="ind" style="color: #2970FF">{{ label }}</span></div>-->
               </div>
           </div>
            <div style="margin: 75px 50px 5px 0;float: right">
                <el-button v-if="Info.uuid == user.uuid" type="primary" class="el-icon-user" @click="toMessage(item)">在线私信</el-button>
                <el-button v-else type="primary" @click="cancel(Info.id)">取消报名</el-button>
            </div>
           <el-dialog :close-on-click-modal="false" :visible.sync="file" width="1194px">
               <MyRecord :data="userData" @getArchives="getArchives"></MyRecord>
           </el-dialog>

        </div>
    </div>
</template>

<script>
import MyRecord from "./myRecord.vue";
import {formatTime, parseTime} from '@/utils/utils'
export default {
    components: {
        MyRecord
    },
    comments:{

    },
    props: {
        Info: {
            type: Object,
            default() {
                return {}
            }

        }
    },
    filters: {
        parseTime, formatTime
    },

    data() {
        return {
            playerOptions: {},
            file:false,
            clickedUuid: null,
            type:"",
            showMessage: false,
            uuid: '',
            nickName: '',
            userData: {},
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

    },

    mounted() {
        this.id = this.$route.params.id;

    },

    methods: {
        // 获取档案信息
        getArchives(uuid) {
            this.newApi.getMyArchives({uuid:uuid}).then((res)=>{
                this.userData = res.data
                this.file = true;
            })
        },
        // 私信
        toMessage(item) {
            const routeData = this.$router.resolve({name: 'News', query: {uuid: item.uuid}})
            window.open(routeData.href, '_blank');
        },
        cancel(id){
            var that = this;
            that.utils.confirm("确定要取消报名吗？",function(){
                that.newApi.cancelMyBusinessSignup({
                    id:id
                }).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.$parent.successful();
                    }
                })
            })
        },
    },
}

</script>
<style scoped>
.Head{
    width: 90px;
    height: 90px;
    margin: 30px 0 0 40px;
    float: left;
    border-radius:5px;
    text-align: center;
}
.name {
    height: 33px;
    font-size: 20px;
    color: #444444;
    padding: 30px 0 0 15px;
}
/deep/ .el-tag {
    height: 25px;
    line-height: 23px;
}

.dialog-title {
    height: 30px;
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

/deep/ .show-message .el-dialog__header{
    padding: 0;
}

/deep/ .show-message .el-dialog__body{
    padding: 0;
}

</style>
