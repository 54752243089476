<template>
    <div class="my-record">
        <div class="right-top-file">


        </div>
        <div class="right-body-file" ref="record">
            <div class="file-header" v-if="user.userType === 1" style="position: relative">
                <div><span class="hand op" style="color: #1479FF; font-size: 14px; font-weight: 600; position: absolute; right:10px;top:10px" @click="toImage()">下载页面（png格式）</span></div>
                <div style="display: flex;">
                    <div style="margin-right: 30px;">
                        <img v-if="data.headIco" :src="data.headIco" width="158px" height="158px">
                    </div>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; ">
                        <div class="tittle-content" style="width: 100%">
                            <span class="tittle-icon" style="text-align: center"><i style="color: #89B7B7;font-size: 26px; font-weight: 600" class="el-icon-user-solid"></i></span>
                            <span class="tittle-name" style="font-size: 20px">个人信息</span>
                        </div>
                        <div style="font-size: 18px; width: 300px">
                            <span style="font-weight: 600">姓名：</span>
                            <span>{{data.realName}}</span>
                        </div>
                        <div style="font-size: 18px; width: 300px">
                            <span style="font-weight: 600">常驻地址：</span>
                            <span v-if="data.address.cityName">{{ data.address.cityName[0] + ' / ' +  data.address.cityName[1]}}</span>
                        </div>
                        <div style="font-size: 18px; width: 300px" v-if="data.phone">
                            <span style="font-weight: 600">手机号码：</span>
                            <span>{{data.phone}}</span>
                        </div>
                        <div style="font-size: 18px; width: 100%" v-if="data.industryType">
                            <span style="font-weight: 600">专业领域：</span>
                            <span v-for="(item, index) in industryShow" :key="index"><span style="font-size: 16px; margin-right: 20px">{{item[0]}}/{{item[1]}}/{{item[2]}}</span></span>
                        </div>
                        <div style="font-size: 18px; width: 100%" v-if="data.skills">
                            <span style="font-weight: 600">业务技能：</span>
                            <span>{{data.skills}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="file-header" v-else style="position: relative">
                <div><span class="hand op" style="color: #1479FF; font-size: 16px; position: absolute; right:10px;top:10px" @click="toImage()">下载全部页面（png格式）</span></div>
                <div style="display: flex;">
                    <div style="margin-right: 30px;">
                        <el-upload class="upload-demo2" drag multiple
                                   width="158px"
                                   action="https://jsonplaceholder.typicode.com/posts/"
                                   :show-file-list="false" :http-request="upload_back"
                                   :before-upload="beforeBackUpload">
                            <img v-if="data.headIco" :src="data.headIco" width="158px" height="158px">
                            <i class="el-icon-plus icon-plus" style="margin-top: 50px; font-size: 40px; font-weight: 600; color: #1479FF"></i>
                        </el-upload>
                    </div>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; ">
                        <div class="tittle-content" style="width: 100%">
                            <span class="tittle-icon" style="text-align: center"><i style="color: #89B7B7;font-size: 26px; font-weight: 600" class="el-icon-user-solid"></i></span>
                            <span class="tittle-name" style="font-size: 20px">机构信息</span>
                        </div>
                        <div style="font-size: 18px; width: 100%" v-if="data.institution">
                            <span style="font-weight: 600">机构名称：</span>
                            <span>{{data.institution.info.Name}}</span>
                        </div>
                        <div style="font-size: 18px; width: 100%" v-if="data.institution">
                            <span style="font-weight: 600">机构介绍：</span>
                            <span>{{data.institution.info.Business}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="user.userType === 1">
                <div class="file-body">
                    <div class="file-tittle image-one">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3590.png">
                            <span class="tittle-name">教育经历</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.schoolInfo" :key="index">
                        <div class="file-content" :style="{borderBottom: index === data.schoolInfo.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div>于{{item.time[0]}}至{{item.time[1]}}就读于{{item.schoolName}}，{{item.major}}专业，获得{{item.degree}}学位</div>
                                <div>就读说明：{{item.explain}}</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file-body">
                    <div class="file-tittle image-two">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3592.png">
                            <span class="tittle-name">工作经历</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.workInfo" :key="index">
                        <div class="file-content" :style="{borderBottom: index === data.workInfo.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div style="margin-bottom: 4px"><span v-if="item.type !== 1">于{{item.time[0]}}至{{item.time[1]}}</span> <span v-else>于{{item.time}}至今</span>就职于{{item.companyName}}，公司职位是{{item.position}}，公司位于{{item.address}}。</div>
                                <div>工作说明：{{item.content}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="file-body">
                    <div class="file-tittle image-three">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3593.png">
                            <span class="tittle-name">项目经历</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.projectInfo" :key="index">
                        <div class="file-content" :style="{borderBottom: index === data.projectInfo.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div style="margin-bottom: 4px">{{item.name}}项目，{{item.time[0]}}至{{item.time[1]}}在{{item.address}}实施</div>
                                <div> 项目实施详情：{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file-body">
                    <div class="file-tittle image-four">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3594.png">
                            <span class="tittle-name">获得荣誉</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.honorInfo" :key="index">
                        <div class="file-content" :style="{borderBottom: index === data.honorInfo.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div style="margin-bottom: 4px">{{item.time}}获得{{item.name}}荣誉</div>
                                <div> 荣誉说明：{{item.content}}</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file-body">
                    <div class="file-tittle image-five">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3585.png">
                            <span class="tittle-name">专业证书</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.professionalInfo.positionalTitles" :key="'positionalTitles' + index">
                        <div class="file-content" :style="{borderBottom: index === data.professionalInfo.positionalTitles.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div>{{item.time}}获得{{item.major}}专业的{{item.name}}职业证书，职称为
                                    <span v-if="item.lv === 1">中级</span>
                                    <span v-if="item.lv === 2">高级</span>
                                    <span v-if="item.lv === 3">正高</span>,
                                    评审机构为{{item.organization}}
                                </div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.professionalInfo.occupation" :key="'occupation' + index">
                        <div class="file-content" :style="{borderBottom: index === data.professionalInfo.occupation.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div>{{item.time}}获得{{item.name}}职业资格证书</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.professionalInfo.otherCert" :key=" 'otherCert' + index">
                        <div class="file-content" :style="{borderBottom: index === data.professionalInfo.otherCert.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div>{{item.time}}获得{{item.name}}证书</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file-body">
                    <div class="file-tittle image-four">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3596.png">
                            <span class="tittle-name">其他佐证</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.otherInfo" :key="index">
                        <div class="file-content" :style="{borderBottom: index === data.otherInfo.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div style="margin-bottom: 4px">{{item.name}}</div>
                                <div> 说明：{{item.content}}</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="file-body">
                    <div class="file-tittle image-one">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3590.png">
                            <span class="tittle-name">机构资质</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.qualification" :key="index" style="font-size: 16px">
                        <div class="file-content" :style="{borderBottom: index === data.qualification.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div>
                                    资质名称：{{item.name}}，{{item.range}}，
                                    <span v-if="item.lv === 1">中级</span>
                                    <span v-if="item.lv === 2">高级</span>
                                    <span v-if="item.lv === 3">正高</span>
                                    。资质有效期：{{item.time}}。</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file-body">
                    <div class="file-tittle image-four">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3594.png">
                            <span class="tittle-name">获得荣誉</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.honorInfo" :key="index">
                        <div class="file-content" :style="{borderBottom: index === data.honorInfo.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div style="margin-bottom: 4px">{{item.time}}获得{{item.name}}荣誉</div>
                                <div> 荣誉说明：{{item.content}}</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file-body">
                    <div class="file-tittle image-four">
                        <div class="tittle-content">
                            <img class="tittle-icon" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/zu3596.png">
                            <span class="tittle-name">其他证书</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in data.otherInfo" :key="index">
                        <div class="file-content" :style="{borderBottom: index === data.otherInfo.length - 1 ? 'none': ''}">
                            <div style="margin-bottom: 10px">
                                <div style="margin-bottom: 4px">{{item.name}}</div>
                                <div> 说明：{{item.content}}</div>
                            </div>
                            <div v-if="item.images">
                                <img v-if="item.images.length > 0" class="pic-Left" :src="item.images[0]"/>
                                <img v-if="item.images.length > 1" class="pic-Right" :src="item.images[1]"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
    name: "myRecord",
    props: {
        type: {
            type: String,
            defaults() {
                return ''
            }
        },
        data: {
            type: Object,
            defaults() {
                return {}
            }
        }
    },
    data() {
        return {
            industryShow: [],

            organData: {
                info: {},
                qualifications: [],
                certificate: []
            }

        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
        is_login(){
            return this.$store.state.is_login
        },
        router(){
            return this.$store.state.router
        }
    },

    created() {
        let list = []
        this.newApi.getTypeList({lv: 3}).then(res => {
            for (const item of this.data.industryType) {
                if (item.typeArr) {
                    list.push(item.typeArr[2])
                } else {
                    list.push(item[2])
                }
            }
            this.industryShow = this.utils.cascader(list, res.data, 'value');
        })
    },

    mounted() {

    },

    methods: {

        // 页面元素转图片
        toImage () {
            // 手动创建一个 canvas 标签
            const canvas = document.createElement("canvas")
            // 获取父标签，意思是这个标签内的 DOM 元素生成图片
            // imageTofile是给截图范围内的父级元素自定义的ref名称
            let canvasBox = this.$refs.record;
            // 获取父级的宽高
            const width = parseInt(window.getComputedStyle(canvasBox).width);
            const height = parseInt(window.getComputedStyle(canvasBox).height);
            // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
            canvas.width = width;
            canvas.height = height;
            canvas.style.width = width + 'px';
            canvas.style.height = height + 'px';
            const options = {
                canvas: canvas,
                useCORS: true,
            };
            html2canvas(canvasBox, options).then((canvas) => {
                // toDataURL 图片格式转成 base64
                let dataURL = canvas.toDataURL("image/png");
                var image = this.base64toFile(dataURL);
                var that = this;
                this.utils.upload(image, function (e) {
                    if (!e){
                        return false;
                    }
                    that.utils.download(e.url, 'record.png', e.hash)
                })
            })
        },

        base64toFile (dataurl, filename = 'file') {
            let arr = dataurl.split(',')
            let mime = arr[0].match(/:(.*?);/)[1]
            let suffix = mime.split('/')[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
            })
        },
    },
}
</script>

<style scoped>
.right-top-file {
    width: 1114px;
    background-color: #fff;
    display: flex;
    justify-content: end;
    padding: 8px 20px;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
}

.right-body-file {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
}

.file-header {
    width: 1094px;
    background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/tem1.png');
    background-size: cover;
    background-repeat:no-repeat;
    padding: 30px;
}

.file-body {
    width: 1054px;
    margin: 20px 0;
}

.file-tittle {
    width: 1114px;
    padding: 10px 20px;
    background-size: cover;
    background-repeat:no-repeat;
    margin-bottom: 20px;
}

.image-one {
    background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/tem2.png');
}

.image-two {
    background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/tem3.png');
}

.image-three {
    background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/tem4.png');
}

.image-four {
    background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/tem5.png');
}

.image-five {
    background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/archives-img/tem6.png');
}

.file-content {
    width: 100%;
    font-size: 20px;
    padding: 30px 40px;
    border-bottom: 1px #8AB8B8 dashed;
}

.pic-Left {
    width: 440px;
    height: 260px;
    border-radius: 5px;
    margin-right: 30px;
}

.pic-Right {
    width: 440px;
    height: 260px;
    border-radius: 5px;
}

.tittle-content {
    display: flex;
}

.tittle-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 20px;
}

.tittle-name {
    font-size: 22px;
    font-weight: 600;
}

/deep/ .upload-demo2 .el-upload-dragger {
    width: 158px;
    height: 158px;
}

/*公用及el*/
.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;
    transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
</style>
