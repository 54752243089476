<template>
    <div>
        <div style="width: 1500px;border-radius: 5px;margin: 0 auto;min-height: 100vh;">
            <!--  模板详情 -->
            <enroll :Info="Info" @cancel-edit="monitor"></enroll>
            <div  style="width: 1500px;border-radius: 5px 5px 5px 5px;height: auto">
                <!--  报名人列表-->
                <registrationList :Info="Info"></registrationList>
            </div>
        </div>

    </div>


</template>
<script>
import Enroll from "./components/enroll";
import registrationList from "./components/registrationList"
export default {
    components: {
        Enroll,
        registrationList

    },

    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    watch: {
        user(newVal, oldVal) {
            // 检查user的值是否已经被更新
            if (newVal !== oldVal) {
                this.getBusinessInfo();
            }
        }
    },
    created() {

    },
    data () {
        return {
            Info:{}
        }
    },
    mounted:function(){
        this.id = this.$route.params.id;
        this.getBusinessInfo();
    },
    methods:{
        successful(){
            let that = this;
            that.getBusinessInfo();
        },
        // 商务信息详情
        getBusinessInfo:function(){
            var that = this;
            that.newApi.getBusinessInfo({
                id:that.id,
            }).then((res)=>{
                that.Info = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 监听子组件执法方法
        monitor(){
            this.getBusinessInfo();
        }
    }
}
</script>